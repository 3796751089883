.card-header {
  padding-top: 3%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80vh; /* Take up full viewport height */
  overflow: hidden; /* Prevent scrolling */
}

.card-header-text {
  width: 50%;
  float: right; /* Align to the right half of the screen */
  text-align: center;
  overflow: hidden; /* Prevent scrolling */
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  margin-left: 5%;
  
}


.card-header-text p{
  font-size: 1.5em;
  padding: 0 5%;
  min-height: 60px;
}

.card-header-image {
  position: relative;
  width: 50%;
  height: 100%;
  text-align: center;
  overflow: hidden; /* Prevent scrolling */
}

.card-image-base {
  z-index: 0; /* Ensures the base image stays behind the GIF */
  animation: moveUpDownRotate 4s ease-in-out infinite;
  position: absolute; /* Position absolutely within the container */
  width: 100%; /* Ensure it covers the container */
  height: 100%; /* Ensure it covers the container */
  object-fit: contain; /* Ensure the image fits within the container without overflow */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
}

.gif-wrapper {
  position: relative; /* Position absolutely within the container */
  width: 100%; /* Ensure it covers the container */
  height: 100%; /* Ensure it covers the container */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  overflow: hidden; /* Prevent scrolling */
}

.card-image-gif {
  z-index: 1; /* Ensures the GIF appears on top */
  position: absolute; /* Position absolutely within the container */
  width: 35vw; /* Adjust as necessary */
  height: auto; /* Adjust as necessary to maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn't exceed container width */
  max-height: 90vh; /* Ensure it doesn't exceed viewport height */
  object-fit: contain; /* Ensure the image fits within the container without distortion */
  top: 5vh; /* Adjust as necessary */
  left: 5vw; /* Adjust as necessary */
}


@keyframes moveUpDownRotate {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(-2deg); /* Adjust rotation degree as needed */
  }
}


.card-header-text h1 {
  font-size: 3em;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 2%;
}

.card-header-text p {
  /* font-size: 1.5em;
  font-family: "Rubik", sans-serif;
  font-weight: 400; */
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.0em
}



@media only screen and (max-width: 800px) {

  .gif-wrapper {
    position: absolute; /* Position absolutely within the container */

  }

  .card-header-image{
    padding-top: 5%;
  }
  
  .card-header {
    flex-direction: column; /* Stack elements vertically */
    height: auto; /* Adjust height for mobile */
    padding: 5% 2%; /* Adjust padding for mobile */
    height: auto; /* Take up full viewport height */
  }

  .card-header-text {
    width: 90%;
    text-align: center;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    order: 2; /* Display text after the image */
    margin-left: 0%
  }

  .card-header-image {
    width: 100%;
    height: auto; /* Adjust height for mobile */
    overflow-y: hidden; /* Prevent vertical scrolling */
    position: relative; /* Ensure proper positioning */
    order: 1; /* Display image before the text */
  }

  .card-image-gif {
    width: 90%;
    height: auto;
    position: relative; /* Ensure proper positioning */
    top: auto; /* Reset top position */
    left: auto; /* Reset left position */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .card-image-base {
    width: 90%;
    height: auto; /* Adjust height for mobile */
    position: relative; /* Ensure proper positioning */
    top: auto; /* Reset top position */
    left: auto; /* Reset left position */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .card-header-text h1 {
    font-size: 2em;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    margin-bottom: 2%;
  }
}