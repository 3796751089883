nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 1%;
    margin-top: 0.5%;
    margin-left: 1%;
    margin-right: 1%;
    overflow: hidden;
}

.nav-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.left, .right {
    display: flex;
    align-items: center;
}

.left {
    justify-content: flex-start;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1.5em;
}

.right {
    justify-content: flex-end;
}

.right * {

    color: black;
    text-decoration: none;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1.5em;
}

.right button {
    background: none;
    border: none;
}

.right button:hover {
    background: none;
    border: none;
    background-color: white;
}

.nav-wes-link {
    color: black;
    text-decoration: none;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 800px) {
    nav {
        flex-direction: column;
        align-items: flex-start;
        padding: 2%;
    }

    .nav-image {
        margin-right: 0;
        margin-bottom: 10px;
        
    }

    .left, .right {
        justify-content: center; /* Center the content */
        width: 100%; /* Ensure full width for centering */
    }

    .right * {
        padding: 10;
        font-size: 1.2em;
        overflow: hidden;
        padding-left: 2%;
    }
}