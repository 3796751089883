.cp-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh; /* Ensure the background takes at least the full viewport height */
  box-sizing: border-box;
  background: linear-gradient(300deg, #87d69f 20%, #f2cf74 50%, #da9df2 80%);
  position: relative; /* To position the noise overlay */
  animation: gradient-animation 18s ease infinite;
  background-size: 200% 200%; /* Increase the background size to allow scrolling */
}

.cp-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box;
}

.base {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; /* Add this */
  bottom: 0; /* Add this */
  flex-direction: column;
}



@keyframes gradient-animation {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}

.header {
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    width: 50%;
    float: left; /* Align to the left half of the screen */
    margin: 5%;

    
}

.topText p{
  max-width: 80%;
}


.topText {
    width: 50%;
    float: right; /* Align to the right half of the screen */
    padding: 5%;
}

