.ckBack {
    display: flex;
    justify-content: center;
    align-items: center;
}


#my-canvas {
    margin: auto; /* Center the canvas within its flex container */
    width: 30%;
    height: 100%;
    border-radius: 25px;
    padding-top: 4%;
}

.ckCanvasBorder{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    border-radius: 25px;
}





@media only screen and (max-width: 800px) {
    #my-canvas {
        width: 80%;
        height: 100%;
        border-radius: 25px;
        padding-top: 4%;
    }
  
  }