.slc{

  align-items: center; /* Centers content vertically if you need it */
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  padding: 1%; /* Simplified padding */
  border-radius: 10px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.slc video{
    width: 100%;
    height: 40%;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-height: 40vh;
}

.slc button{
  background-color: #87d69f;
  padding: 2%;
  border-radius: 10px;
  border: none;
  font-size: 1.5em;
  color: black; /* Set the text color */
  text-decoration: none; /* Remove underline from links */
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}


@media only screen and (max-width: 800px) {
  .slc {
    margin-bottom: 5%;
  }


}