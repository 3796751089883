.cardImg {
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Adds a shadow */
  z-index: 1;
  margin-top: 7%;
}

.card-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  max-width: 18%;
  text-align: center;
  padding-bottom: 3%;
  margin-left: 1%;
  margin-right: 1%;
  overflow: hidden; /* Prevent overflow issues */
}

.card-desc {
  font-size: 1.5em;
  padding: 0 5%;
  min-height: 60px;
}

.card-text {
  font-size: 2em;
  justify-content: flex-start;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

@keyframes moveUpDown {
  0%, 16.67% { /* 2s movement in a 12s cycle: 2/12 = 16.67% */
      transform: translateY(0);
  }
  8.33% { /* Midpoint of movement at 1s */
      transform: translateY(-20px);
  }
  16.67%, 100% { /* Remain stationary after movement until the end of the cycle */
      transform: translateY(0);
  }
}

.card-link, .card-link:visited, .card-link:link, .card-link:active {
  background-color: #87d69f;
  padding: 2%;
  border-radius: 10px;
  font-size: 1.5em;
  color: black; /* Set the text color */
  text-decoration: none; /* Remove underline from links */
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.card-link:hover {
  background-color: #5cac74;
  padding: 2%;
  border-radius: 10px;
  font-size: 1.5em;
  color: black; /* Set the text color */
  text-decoration: none; /* Remove underline from links */
  font-family: "Rubik", sans-serif;
}

@media only screen and (max-width: 800px) {
  .card-base {
      min-width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: 10%;
      padding-top: 10%;
  }

  .cardImg {
      max-height: 300px;
      max-width: 70%;
  }

  .card-desc {
      font-size: 1em; /* Reduce font size for smaller screens */
      padding: 0 2%; /* Reduce padding for smaller screens */
  }

  .card-link, .card-link:visited, .card-link:link, .card-link:active, .card-link:hover {
      padding: 2%; /* Reduce padding for smaller screens */
      font-size: 1.5em; /* Reduce font size for smaller screens */
  }
}