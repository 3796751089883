.ARC-base {
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1;
  padding-top: 10%;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.arc-text {
  /* font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.2em; */
  padding-left: 10%;
  padding-right: 10%;
  width: 50%;
  margin: auto; /* Centers horizontally */
  display: flex; /* Makes .arc-text a flex container */
  justify-content: center; /* Centers children horizontally inside .arc-text */
  align-items: center; /* Centers children vertically inside .arc-text */
  text-align: center; /* Centers text inside .arc-text */
  flex-direction: column; /* Stacks children vertically inside .arc-text */
}

.arc-text p{
  min-height: 60px;
  font-size: 1.5em;
  padding: 0 5%;
}

.arc-img {
  display: flex; /* Use flexbox to center the images */
  justify-content: center; /* Center the images horizontally */
  align-items: center; /* Center the images vertically */
  overflow: hidden;
}

.arc-img img {
  width: 40%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%); /* Keep this to center the first image */
  transition: transform 0.3s ease;
  padding-top: 10%;
}

.arc-img img:nth-child(1) {
  z-index: 1;
  animation: upAndDownCenter 3s ease-in-out infinite; /* Apply the animation */
}

.arc-img img:nth-child(2), .arc-img img:nth-child(3) {
  top: 50%;
}

.arc-img img:nth-child(2) {
  /* Adjusted translate values for better centering */
  transform: translate(-200%, -0%) rotate(-15deg); /* Adjust the percentage as needed */
  z-index: -1;
  animation: upAndDown 2s ease-in-out infinite; /* Apply the animation */
}

.arc-img img:nth-child(3) {
  /* Adjusted translate values for better centering */
  transform: translate(-200%, -0%) rotate(15deg); /* Adjust the percentage as needed */
  z-index: -1;
  animation: upAndDownThird 4s ease-in-out infinite; /* Separate animation for the 3rd child to maintain its rotation */
}

@keyframes upAndDown {
  0%, 100% {
      transform: translate(-200%, -0%) rotate(-10deg); /* Original transform */
  }
  50% {
      transform: translate(-200%, -20px) rotate(-15deg); /* Move up */
  }
}

@keyframes upAndDownThird {
  0%, 100% {
      transform: translate(-200%, -0%) rotate(10deg); /* Original transform for the 3rd child */
  }
  50% {
      transform: translate(-200%, -20px) rotate(15deg); /* Move up */
  }
}

@keyframes upAndDownCenter {
  0%, 100% {
      transform: translate(-50%, -0%) rotate(0deg); /* Original transform for the 3rd child */
  }
  50% {
      transform: translate(-50%, -20px) rotate(0deg); /* Move up */
  }
}

.arc-text h1 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 10%;
  z-index: 100;
}

/* Media query for mobile devices */
@media (max-width: 800px) {
  .ARC-base {
      flex-direction: column;
      width: 80%;
      margin-left: 5%;
  }

  .arc-img {
      order: -1; /* Move images before text */
  }

  .arc-text {
    width: 90%;
    padding-left: 5%; /* Adjust padding for mobile */
    padding-right: 5%; /* Adjust padding for mobile */

  }

  .arc-img img {
      width: 80%;
      padding-top: 5%;
      
  }
}