/* ContactField.css */



.form-container {
    display: flex;
    justify-content: flex-start; /* Aligns content to the start of the container in the Y dimension */
    align-items: center; /* Keeps content centered horizontally */
    flex-direction: column;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.form-container h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 2em;
    margin-bottom: 10%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

label {
    font-weight: bold;
    margin-bottom: 10px;
}

input,
textarea {
    width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.submission-message h1{
    color: black; /* Set the text color */
    text-decoration: none; /* Remove underline from links */
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1em;
    text-align: center;
}

.form-container,  h1 {
    color: black; /* Set the text color */
    text-decoration: none; /* Remove underline from links */
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1em;
    text-align: center;
}


.form-container button{
    background-color: #87d69f;
    padding: 2%;
    border-radius: 10px;
    font-size: 1.5em;
    color: black; /* Set the text color */
    text-decoration: none; /* Remove underline from links */
    font-family: "Rubik", sans-serif;
    font-weight: 500;
}

.error-message{
    padding-top: 5%;
    color: red;
    font-size: 1.2em;
    margin-bottom: 10px;
}


@media only screen and (max-width: 800px) {
    .form-container {
        max-width: 90%;
        margin-left: 5%;
        margin-right: 5%;

    }
    
  }