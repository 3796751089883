.co-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh; /* Ensure the background takes at least the full viewport height */
  box-sizing: border-box;
  background: linear-gradient(300deg, #87d69f 20%, #f2cf74 50%, #da9df2 80%);
  position: relative; /* To position the noise overlay */
  animation: gradient-animation 18s ease infinite;
  background-size: 200% 200%; /* Increase the background size to allow scrolling */
}

.co-container {
  position: relative;
  z-index: 2; /* Ensure content is above the images */
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box;
}
  
  .base {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; /* Add this */
    bottom: 0; /* Add this */
    flex-direction: column;
  }
  




  .contact-square {
    position: absolute;
    z-index: 1;
    left: 20vw; /* Use viewport width */
    top: 5vh;
    animation: moveUpDownRotate 4s ease-in-out infinite;
  }
  
  .contact-square img {
    width: 30vw ; /* Use viewport width */
  }
  


  .contact-blast {
    position: absolute;
    z-index: 1;
    left: 70vw; /* Use viewport width */
    bottom: 0vh;
    animation: moveUpDownRotateTwo 6s ease-in-out infinite;
  }
  
  .contact-blast img {
    width: 30vw; /* Use viewport width */
  }
  



  
  @keyframes moveUpDownRotate {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-20px) rotate(-2deg); /* Adjust rotation degree as needed */
    }
  }


  @keyframes moveUpDownRotateTwo {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(20px) rotate(2deg); /* Adjust rotation degree as needed */
    }
  }


  /* Media Query for Mobile Devices */
@media only screen and (max-width: 800px) {

  .co-container{
    min-height: 100vh;
  }
  .contact-square {
    position: absolute;
    z-index: 1;
    top: 70vh;
    animation: moveUpDownRotate 4s ease-in-out infinite;
  }
  
  .contact-square img {
    width:30vw; /* Use viewport width */
  }
  


  .contact-blast {
    position: absolute;
    z-index: 1;
    left: 70vw; /* Use viewport width */
    top: 90vh;
    /* animation: moveUpDownRotateTwo 6s ease-in-out infinite; */
  }
  
  .contact-blast img {
    width: 100vw; /* Use viewport width */
  }
  

}