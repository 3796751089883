.vrC-base {
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1;
  padding-top: 10%;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  margin-left: 5%;
}

.vrc-text {
  /* font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.2em; */
  padding-left: 10%;
  padding-right: 10%;
  width: 50%;
  margin: auto; /* Centers horizontally */
  display: flex; /* Makes .arc-text a flex container */
  justify-content: center; /* Centers children horizontally inside .arc-text */
  align-items: center; /* Centers children vertically inside .arc-text */
  text-align: center; /* Centers text inside .arc-text */
  flex-direction: column; /* Stacks children vertically inside .arc-text */
}



.vrc-text p{
  min-height: 60px;
  font-size: 1.5em;
  padding: 0 5%;
}
.vrc-img {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  grid-template-rows: auto auto; /* Two rows, height adjusts to content */
  gap: 10px; /* Adjust the gap as needed, or specify as 'row-gap column-gap' */
  max-width: 50%;
  place-items: center; /* Center the grid */
  overflow: hidden;
}

.vrc-img img {
  width: 60%; /* Adjust images to fill their cell completely */
  height: auto; /* Maintain aspect ratio */
  padding-top: 10%;
  padding-bottom: 10%;
}

@keyframes wiggle {
  0%, 100% {
      transform: rotate(-5deg);
  }
  50% {
      transform: rotate(5deg);
  }
}

.vrc-img img:nth-child(1) {
  animation: wiggle 4s infinite;
  animation-delay: 0.1s; /* Slightly different offset for each image */
}

.vrc-img img:nth-child(2) {
  animation: wiggle 4s infinite;
  animation-delay: 0.2s;
}

.vrc-img img:nth-child(3) {
  animation: wiggle 4s infinite;
  animation-delay: 0.3s;
}

.vrc-img img:nth-child(4) {
  animation: wiggle 4s infinite;
  animation-delay: 0.4s;
}

.vrc-text h1 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 10%;
  z-index: 100;
}

/* Media query for mobile devices */
@media (max-width: 800px) {
  .vrC-base {
      flex-direction: column;
      width: 80%;
      margin-left: 5%;
  }

  .vrc-img {
      order: -1; /* Move images before text */
      max-width: 100%; /* Adjust max-width for mobile */
  }

  .vrc-text {
      width: 90%;
      padding-left: 5%; /* Adjust padding for mobile */
      padding-right: 5%; /* Adjust padding for mobile */
  }

  .vrc-img img {
      width: 80%; /* Adjust image width for mobile */
      padding-top: 5%;
      padding-bottom: 5%;
  }
}