.privacy-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh; /* Ensure the background takes at least the full viewport height */
  box-sizing: border-box;
  background: linear-gradient(300deg, #87d69f 20%, #f2cf74 50%, #da9df2 80%);
  position: relative; /* To position the noise overlay */
  animation: gradient-animation 18s ease infinite;
  background-size: 200% 200%; /* Increase the background size to allow scrolling */
}

.privacy-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box;
}
  
.privacy-header{
  font-size: 3em;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 2%;
}

  /* Debugging overflow issues */
/* Debugging overflow issues */
* {

  overflow-x: hidden; /* Prevent horizontal overflow for all elements */
}