.container-socials {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  margin-top: 7%;
  border-radius: 10px;
  width: 70%;

  margin-left: 15%;
}

.socials-text {
    padding-top: 5%;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
}


.social-logos{
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-logo{
    width: 5%;
    padding: 5%;


  }

.container-socials h1{
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
}

.container-socials p{
  font-size: 1.5em;
  padding: 0 5%;
  min-height: 60px;
}


@media only screen and (max-width: 800px) {

  .container-socials{
    width: 90%;
    margin-left: 5%;
  }

  .socials-text{
    padding-left: 3%;
    padding-right: 3%;
  }

  .client-logo{
    width: 20%;
    padding: 5%;


  }
}