.s-baseHeader {
    display: flex;
    justify-content: center; /* Aligns items horizontally in the center */
    align-items: center; /* Aligns items vertically in the center */
    flex-direction: column; /* Stacks items vertically. Remove if you want horizontal layout */
    text-align: center; 
}

.s-baseHeader h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 3em;
    margin-bottom: 5%;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.why-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

/* Media query for mobile devices */
@media (max-width: 800px) {
    .why-container {
        flex-direction: column;
    }

    .s-baseHeader h1 {
        font-family: "Rubik", sans-serif;
        font-weight: 500;
        font-size: 2em;
        margin-bottom: 5%;
        padding-left: 0%;
        padding-right: 0%;
        padding-top: 0%;
        padding-bottom: 0%;
        margin-left: 5%;
        margin-right: 5%;
        
    }
}