.ch-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap; /* Allow wrapping of items */
  width: 100%; /* Ensure it takes full width */
  overflow: hidden; /* Prevent overflow */
}


.product-header{
  font-size: 3em;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 2%;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.product-parent-container {
  display: flex;
  justify-content: center;
}



@media only screen and (max-width: 800px) {
  .ch-container {
    display: grid; /* Use CSS Grid */
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    width: 100%;
    overflow: hidden; /* Prevent overflow */
    row-gap: 10px; /* Add gap only at the bottom of each item */
  }

  .ch-container > .card:nth-child(5) {
    grid-column: span 2; /* Make the last item span 2 columns */
  }
}