body {
  overflow: hidden; /* Prevent scrolling on the entire page */
}

.header-wrapper {
  position: relative;
  height: auto; /* Adjust based on content, or use a specific height */
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  align-items: flex-start; /* Keeps items aligned to the start */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrolling */
}

.header-container {
  position: relative;
  z-index: 2; /* Ensure content is above the images */
  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  overflow: hidden; /* Prevent scrolling */
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  margin-bottom: 5%;
}

.header-video {

  padding: 20px;
  width: 50%;
  float: left; /* Align to the left half of the screen */
  margin: 5%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent scrolling */
}

.header-text {
  width: 50%;
  float: right; /* Align to the right half of the screen */
  padding: 5%;
  overflow: hidden; /* Prevent scrolling */

  
}


.header-text p{
  font-size: 1.5em;
  padding: 0 5%;
  min-height: 60px;
}

.header-image-one {
  position: absolute;
  z-index: 1;
  left: 0vw; /* Use viewport width */
  top: 10vh;
  animation: wiggleAnimationHome 8s ease-in-out infinite;
}

.header-image-one img {
  width: 20vw; /* Use viewport width */
}

.header-image-two {
  position: absolute;
  z-index: 1;
  right: 3vw; /* Use viewport width */
  top: 55vh; /* Use viewport height */
  animation: wiggleAnimationHome 5s ease-in-out infinite;
}

.header-image-two img {
  width: 20vw; /* Use viewport width */
}

.header-text h1 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
}



@keyframes wiggleAnimationHome {
  0%,
  100% {
    transform: translateY(-10%) rotate(-2deg);
  }
  50% {
    transform: translateY(-20%) rotate(2deg);
  }
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 800px) {
  .header-container {
    flex-direction: column; /* Stack elements vertically */
    padding-left: 5%; /* Remove left padding */
    padding-right: 5%; /* Remove right padding */
    margin-left: 10%;
    margin-right: 10%;
    align-items: center; /* Center align items */
    overflow: hidden; /* Prevent scrolling */
  }

  .header-video {
    order: 1; /* Render video first */
    width: 100%; /* Full width on mobile */
    margin: 0; /* Remove margin */
    padding: 5px; /* Further reduce padding */
    border-radius: 0; /* Remove border radius */
    min-height: 0vh;
    padding-left: 20%;
    padding-right: 20%;
    min-height: 37vh;
    overflow: hidden; /* Prevent scrolling */
  }

  .header-text {
    order: 2; /* Render text underneath */
    width: 90%; /* Full width on mobile */
    padding: 5%; /* Adjust padding */
    float: none; /* Remove float */
    margin: 0; /* Remove margin */
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden; /* Prevent scrolling */
  }

  .header-wrapper {
    padding-bottom: 0; /* Remove bottom padding */
    overflow: hidden; /* Prevent scrolling */
  }



  .header-image-one {
    position: absolute;
    z-index: 1;
    left: -10vw; /* Use viewport width */
    top: 10vh;
    animation: wiggleAnimationHome 8s ease-in-out infinite;
  }
  
  .header-image-one img {
    width: 40vw; /* Use viewport width */
  }
  
  .header-image-two {
    position: absolute;
    z-index: 1;
    right: 0vw; /* Use viewport width */
    top: 80vh; /* Use viewport height */
    animation: wiggleAnimationHome 5s ease-in-out infinite;
  }
  
  .header-image-two img {
    width: 30vw; /* Use viewport width */
  }
  
  .header-text h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 2em;
  }


}