.rs-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
    flex-wrap: wrap; /* Allow wrapping of items */
    position: relative; /* Ensure relative positioning for absolute children */
    min-height: 100vh; /* Ensure it covers the viewport height */
}

.rs-header {
    font-size: 3em;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    margin-bottom: 2%;
    margin-top: 2%;
    text-align: center;
    overflow: hidden;
}

.rs-header-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%; /* Adjust width to 100% to center horizontally */
    margin-top: 5%; /* Remove margin-top to center vertically */
}

.rs-header-background {
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 0.5em 1em; /* Add padding around the text */
}

.rs-squigle {
    position: absolute;
    top: 60%; /* Adjust based on .header-wrapper's margin-top and its own offset */
    right: 60%; /* Adjusted to simulate positioning relative to .header-wrapper */
    z-index: -1;
    width: 20%;
    animation: wiggle 5s ease-in-out infinite;
}


.rs-header h1{

}

@media only screen and (max-width: 800px) {
    .rs-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center elements horizontally */
        margin-top: 5%; /* Adjust margin for mobile */
        min-height: 100vh; /* Ensure it covers the viewport height */
    }

    .rs-header {
        font-size: 2em; /* Adjust font size for mobile */
        padding-top: 5%; /* Adjust padding for mobile */
    }

    .rs-squigle {
        top: 70%; /* Adjust position for mobile */
        right: 50%; /* Adjust position for mobile */
        width: 30%; /* Adjust width for mobile */
    }
}