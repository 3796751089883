.h-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh; /* Ensure the background takes at least the full viewport height */
  box-sizing: border-box;
  background: linear-gradient(300deg, #87d69f 20%, #f2cf74 50%, #da9df2 80%);
  position: relative; /* To position the noise overlay */
  animation: gradient-animation 18s ease infinite;
  background-size: 200% 200%; /* Increase the background size to allow scrolling */
}

.h-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box;
}
  
  .base {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; /* Add this */
    bottom: 0; /* Add this */
    flex-direction: column;
  }
  


.contact-square {
    position: absolute; /* Positions both images absolutely within the container */
    top: 10;
    left: 1%;
    width: 30%; /* Ensures images cover the container. Adjust as necessary */
    height: auto; /* Adjust as necessary to maintain aspect ratio */
    animation: moveUpDownRotate 4s ease-in-out infinite;
    z-index: -100; /* Ensures the base image stays behind the GIF */
  }


  .contact-blast {
    position: absolute; /* Positions both images absolutely within the container */
    top: 20%;
    right: 1%;
    width: 30%; /* Ensures images cover the container. Adjust as necessary */
    height: auto; /* Adjust as necessary to maintain aspect ratio */
    animation: moveUpDownRotateTwo 6s ease-in-out infinite;
    z-index: -100; /* Ensures the base image stays behind the GIF */
  }
  
  @keyframes moveUpDownRotate {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-20px) rotate(-2deg); /* Adjust rotation degree as needed */
    }
  }


  @keyframes moveUpDownRotateTwo {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(20px) rotate(2deg); /* Adjust rotation degree as needed */
    }
  }

  /* Debugging overflow issues */
/* Debugging overflow issues */
* {

  overflow-x: hidden; /* Prevent horizontal overflow for all elements */
}