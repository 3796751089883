.why-base {
  /* background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  text-align: center;
  width: 43%;
  margin-left: 5%;
  margin-right: 5%;
  z-index: -1;
  min-height: 50vh; */

  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  width: 43%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 10px;
  text-align: center;
  padding-top: 10%;
}

.why-base img {
  width: 80%;
  border-radius: 10px;
  padding-bottom: 20%;
}

.why-base h2 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 10%;
  z-index: 100;
}


.why-base p{
    /* font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1.2em; */
    padding-left: 10%;
    padding-right: 10%;

}

.why-base img {
  /* Existing styles */
  margin-top: -10%;
  margin-bottom: -30%;
  animation-iteration-count: infinite;
  z-index: -1;
}

.wiggle {
  animation-duration: 4s;
  animation-name: wiggleAnimation;
  animation-timing-function: linear;
}

@keyframes wiggleAnimation {
  0%,
  100% {
    transform: translateY(-10%) rotate(-2deg);
  }
  50% {
    transform: translateY(-20%) rotate(2deg);
  }
}

.wiggle2 {
  animation-duration: 3s;
  animation-name: wiggleAnimation2;
  animation-timing-function: linear;
}

@keyframes wiggleAnimation2 {
  0%,
  100% {
    transform: translateY(-10%) rotate(-5deg);
  }
  50% {
    transform: translateY(-20%) rotate(5deg);
  }
}

.wiggle3 {
  animation-duration: 5s;
  animation-name: wiggleAnimation3;
  animation-timing-function: ease-in-out;
}

@keyframes wiggleAnimation3 {
  0%,
  100% {
    transform: translateY(-10%) rotate(-4deg);
  }
  50% {
    transform: translateY(-20%) rotate(4deg);
  }
}


/* Media query for mobile devices */
@media (max-width: 800px) {
  .why-base {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 10px;
    text-align: center;
    padding-top: 10%;
    margin-bottom: 15%;
  }

  .why-base img {
    width: 50%;
    border-radius: 10px;
    padding-bottom: 20%;
    padding-top: 20%;
  }
}