.try-background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh; /* Ensure the background takes at least the full viewport height */
  box-sizing: border-box;
  background: linear-gradient(300deg, #87d69f 20%, #f2cf74 50%, #da9df2 80%);
  position: relative; /* To position the noise overlay */
  animation: gradient-animation 18s ease infinite;
  background-size: 200% 200%; /* Increase the background size to allow scrolling */
}

.try-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden; /* Prevent overflow */
  box-sizing: border-box;
}

.tRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px; /* Adjusted padding: top & bottom, left & right */
  align-items: center; /* Align items in the center vertically */
}

.tryHeader {
  padding: 10px 10px; /* Adjusted padding: top & bottom, left & right */
  border-radius: 10px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.tryHeader h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 2em;
  }


/* Media Query for Mobile Devices */
@media only screen and (max-width: 800px) {
    .tRow {
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center items horizontally */
    }
  

  }