.media-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  overflow: hidden;
  display: flex;
  align-items: center; /* Vertically center the children */
  justify-content: center; /* Horizontally center the children */
}
  
  .media {
    width: 100%;
    height: auto;
    animation-fill-mode: forwards; /* Ensures the animation stays at the last frame when completed */
  }
  
  .fade-in {
    animation: fadeInAnimation 1s ease-in-out;
  }
  
  .fade-out {
    animation: fadeOutAnimation 1s ease-in-out;
  }
  
  @keyframes fadeInAnimation {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOutAnimation {
    from { opacity: 1; }
    to { opacity: 0; }
  }