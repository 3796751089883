


.rs-base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(50px);
    border-radius: 10px;
    width: 500px;
    min-height: 200px; /* Reduced min-height */
    text-align: center;
    padding: 5% 1%; /* Reduced padding */
    margin-top: 5%; /* Reduced margin-top for closer spacing */
    padding-top: 10%;
    box-sizing: border-box;
    overflow: hidden; /* Prevent scrolling */
}

.rs-card {
    width: 100px;
    margin: auto;
    margin-bottom: -60px; /* Pulls the next element (rs-base) upwards to overlap */
    display: block; /* Ensures it's treated as a block-level element, which respects margin: auto */
    border-radius: 7%;
    z-index: 2; /* Ensure it's higher than the base but doesn't need to be excessively high */
    position: relative;
}

.rs-stars {
    display: block;
    padding-top: 10%;
    margin: -20px auto 10px; /* Adjust the negative margin as needed to overlap */
    max-width: 80%;
    height: 50px;
    width: auto;
    /* Other styles remain unchanged */
}

.rs-base p {
    font-size: 1.5em;
    padding: 0 5%; /* Adjusted padding for consistency */
    min-height: 60px; /* Example min-height to ensure text blocks are consistently sized */
}

.rs-r {
    font-family: "Playwrite DE Grund", cursive;
    font-weight: 400;
    font-size: 1em;
    font-style: normal;
}

@media only screen and (max-width: 800px) {
    .rs-base {
        width: 90%; /* Adjust width for mobile */
        margin-left: 5%;
    }

    .rs-card {
        width: 80px; /* Adjust card size for mobile */
        margin-bottom: -40px; /* Adjust margin for mobile */
        padding-top: 20%;
    }

    .rs-stars {
        padding-top: 5%; /* Adjust padding for mobile */
        margin: -10px auto 5px; /* Adjust margin for mobile */
    }

    .rs-base p {
        font-size: 1.2em; /* Adjust font size for mobile */
        padding: 0 10%; /* Adjust padding for mobile */
    }

    .rs-r {
        font-size: 0.9em; /* Adjust font size for mobile */
    }
}